import React from "react";

export default function MainContent() {
  return (
    <>
      <div className="maincontent">
        <div className="maincontentFiller">
          <p>As a Family at Lindy's Local <br></br> We Believe In Making an Honest Difference To Protect Your Home and Family.</p>
        </div>
        <div className="why">
          <p>
            At Lindy's Local Your Family Matters. We Believe In Making A
            Difference By Protecting Your Loved Ones.
          </p>
        </div>
      </div>
    </>
  );
}
