import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from '@vercel/analytics/react';
import Nav1 from './Nav1';
import Nav2 from './Nav2';
import MainContent from './MainContent';
import Bugs from './Bugs';
import House from './House';
import Thankyou from './Thankyou';
import Footer from './Footer';
import Location from './Location';
import BugsPage from './BugsPage';
import ContactPage from './ContactPage';
import AboutPage from './AboutPage';
import Ants from './bugs/ants';
import BoxElders from './bugs/box-elders';
import Earwigs from './bugs/earwigs';
import Fleas from './bugs/fleas';
// import Mosquitos from './bugs/mosquitos';
import Roaches from './bugs/roaches';
import Rodents from './bugs/rodents';
import Spiders from './bugs/spiders';
import Wasps from './bugs/wasps';
import Privacy from './Privacy';
import Already from './Already';
import How from './How';
import '../css/index.css';

function App() {
  return (
    <>
      <div className="app">
        <Routes>
          <Route path='/' exact element={<>
            <Nav1/>
            <Nav2/>
            <MainContent/>
            <How/>
            <Bugs/>
            <House/>
            <Thankyou/>
            <Footer/>
          </>}>
          </Route>

          <Route path='/location' exact element={<Location/>}>
            
          </Route>
          <Route path='/bugs' exact element={<BugsPage/>}>
          </Route>
          <Route path='/contact' exact element={<ContactPage/>}>
          </Route>
          <Route path='/about' exact element={<AboutPage/>}>
          </Route>
          <Route path='/privacy' exact element={<Privacy/>}>
          </Route>
          <Route path='/already' exact element={<Already/>}>
          </Route>

          <Route path='/ants' exact element={<Ants/>}>
          </Route>
          <Route path='/boxelders' exact element={<BoxElders/>}>
          </Route>
          <Route path='/earwigs' exact element={<Earwigs/>}>
          </Route>
          {/* <Route path='/mosquitos' exact>
            <Mosquitos/>
          </Route> */}
          <Route path='/roaches' exact element={<Roaches/>}>
          </Route>
          <Route path='/rodents' exact element={<Rodents/>}>
          </Route>
          <Route path='/spiders' exact element={<Spiders/>}>
          </Route>
          <Route path='/wasps' exact element={<Wasps/>}>
          </Route>
          <Route path='/fleas' exact element={<Fleas/>}>
          </Route>
        </Routes>
        <SpeedInsights />
        <Analytics />
      </div>
    </>
  );
}

export default App;
